import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getLinkedImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/linked-website/image-upload-url/`, data)).data.data;
  },
  async createBasicConfig(_obj, data) {
    return axios.patch(`api/linked-website/update-school-website/`, data);
  },
  async updateNavbar(_obj, data) {
    return axios.patch(`api/linked-website/update-website-navbar/`, data);
  },
  async getBasicConfig(_obj, params) {
    const res = await axios.get(`api/linked-website/school-website/`, { params });
    return res.data.data;
  },

  async createAboutConfig(_obj, data) {
    return axios.patch(`api/linked-website/update-website-aboutpage/`, data);
  },

  async getAboutPage(_obj, params) {
    const res = await axios.get(`api/linked-website/school-aboutpage/`, { params });
    return res.data.data;
  },

  async createTuitionConfig(_obj, data) {
    return axios.patch(`api/linked-website/update-website-tuitionpage/`, data);
  },

  async getTuitionPage(_obj, params) {
    const res = await axios.get(`api/linked-website/school-tuition-page/`, { params });
    return res.data.data;
  },
  async getHomePageConfig(_obj, params) {
    const res = await axios.get(`api/linked-website/school-homepage/`, { params });
    return res.data.data;
  },
  async createHomepageConfig(_obj, data) {
    return axios.patch(`api/linked-website/update-website-homepage/`, data);
  },
  async addCampusesConfig(_obj, data) {
    return await axios.post(`api/schools/campus-location/`, data);
  },
  async getCampusesConfig(_obj, params) {
    const res = await axios.get(`api/schools/campus-location/`, { params });
    return res.data.data;
  },
  async updateCampusesConfig(_obj, { id, data }) {
    const res = await axios.patch(`api/schools/campus-location/${id}/`, data);
    return res.data;
  },
  async getCampus(_obj, id) {
    const res = await axios.get(`api/schools/campus-location/${id}/`);
    return res.data;
  },
  async updateCampusesOrder(_obj, data) {
    return axios.patch(`api/schools/campus-location/update-cmps-location-order/`, data);
  },
  async deleteCampus(_obj, id) {
    return axios.delete(`api/schools/campus-location/${id}/`);
  },
  async createPhotoGallery(_obj, data) {
    return await axios.post(`api/schools/add-school-photos/`, data);
  },

  async deletePhotoGallery(_obj, data) {
    return await axios.post(`api/schools/delete-school-photos/`, data);
  },

  async getAllGalleryPhotos(_obj, params) {
    const res = await axios.get(`api/linked-website/all-school-photos/`, { params });
    return res.data.data;
  },
  async createFaq(_obj, data) {
    return await axios.post(`api/linked-website/add-school-faqs/`, data);
  },
  async updateFaq(_obj, { id, data }) {
    const res = await axios.patch(`api/linked-website/${id}/update-school-faqs/`, data);
    return res.data;
  },
  async getFaq(_obj, id) {
    const res = await axios.get(`api/linked-website/${id}/school-faq-obj/`);
    return res.data;
  },
  async updateFaqsOrder(_obj, data) {
    return axios.patch(`api/linked-website/update-website-faqs-order/`, data);
  },
  async getFaqs(_obj, params) {
    const res = await axios.get(`api/linked-website/portal-school-faqs/`, { params });
    return res.data.data;
  },
  async deleteFaq(_obj, id) {
    return axios.delete(`api/linked-website/${id}/delete-website-faqs/`);
  },
  async createResource(_obj, data) {
    return await axios.post(`api/linked-website/school-resources/`, data);
  },
  async updateResource(_obj, { id, data }) {
    const res = await axios.patch(`api/linked-website/school-resources/${id}/`, data);
    return res.data;
  },
  async updateResourceStatus(_obj, { id, data }) {
    const res = await axios.patch(`api/linked-website/school-resources/${id}/update-is-visible/`, data);
    return res.data;
  },
  async getResource(_obj, id) {
    const res = await axios.get(`api/linked-website/school-resources/${id}/`);
    return res.data;
  },
  async getResources(_obj, params) {
    const res = await axios.get(`api/linked-website/school-resources/`, { params });
    return res.data.data;
  },
  async deleteResource(_obj, id) {
    return axios.delete(`api/linked-website/school-resources/${id}/`);
  },

  async createCustomPage(_obj, data) {
    return await axios.post(`api/linked-website/custom-pages/`, data);
  },
  async updateCustomPage(_obj, { id, data }) {
    const res = await axios.patch(`api/linked-website/custom-pages/${id}/`, data);
    return res.data;
  },

  async getCustomPage(_obj, id) {
    const res = await axios.get(`api/linked-website/custom-pages/${id}/`);
    return res.data;
  },
  async getCustomPages(_obj, params) {
    const res = await axios.get(`api/linked-website/custom-pages/`, { params });
    return res.data.data;
  },
  async deleteCustomPage(_obj, id) {
    return axios.delete(`api/linked-website/custom-pages/${id}/`);
  }
};

export const webConfig = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
